import React from "react";
import PropTypes from "prop-types";
import { StyledFormContainer } from "./styles";
import {
	Text,
	CloudinaryImage,
	Grid,
	Col,
	HubspotForm,
	HubspotStagedForm,
	HorizontalList,
} from "@website-builder/ui/shared/elements";
import { richTextResolver } from "@website-builder/utilities/utils/TransformerHelpers.js";
import { TEXT_COLORS } from "@website-builder/utilities/constants/style-constants/colors.js";

const FormContainer = (props) => {
	const {
		isFullWidthForm,
		content,
		formDetails,
		hasDarkBackground,
		hasFormOnLeft,
		topBackgroundColor,
		bottomBackgroundColor,
		itemsOnFirstColumn,
		brandBar,
		pageSection = "",
		context,
	} = props;
	const headingColor = !hasDarkBackground
		? TEXT_COLORS.HEADING_DARK
		: TEXT_COLORS.HEADING_LIGHT;
	const textColor = !hasDarkBackground
		? TEXT_COLORS.TEXT_DARK
		: TEXT_COLORS.TEXT_LIGHT;

	const hasStagedForm = formDetails?.component === "hubspotStagedForm";

	const brandBarJSX = brandBar ? (
		<HorizontalList
			className="additional-items-brand-bar"
			listItems={brandBar?.brandLogos?.map((logo, i) => {
				if (logo?.image?.image) {
					return (
						<CloudinaryImage
							className="hubspot-form-logos"
							key={`logo-image-${i}`}
							url={logo?.image?.image}
							quality="auto"
							lazyload
							dynamic
							fetchFormat="auto"
							sizes={{
								default: {
									height: 20,
								},
							}}
							alt={logo?.image?.altTag}
						/>
					);
				}
			})}
			maxItems={parseInt(brandBar?.maxLogosInRow, 10)}
			labelConfig={{
				text: brandBar?.labelText,
			}}
			gap="30px"
		/>
	) : null;

	const formJSX = () => {
		let formColStart = 3;
		let formColSpan = 8;
		if (!isFullWidthForm) {
			formColStart = hasFormOnLeft ? 1 : 6;
			formColSpan = 7;
		}
		if (!!itemsOnFirstColumn) {
			formColStart = 0;
			formColSpan = 12;
		}
		return (
			<Col start={formColStart} span={formColSpan} mdSpan={12} hAlign="center">
				{hasStagedForm ? (
					<HubspotStagedForm
						formDetails={formDetails}
						context={context}
						pageSection={pageSection}
					/>
				) : (
					<HubspotForm
						formDetails={formDetails}
						context={context}
						pageSection={pageSection}
						itemsOnFirstColumn={itemsOnFirstColumn || null}
						additionalItems={[brandBarJSX]}
					></HubspotForm>
				)}
			</Col>
		);
	};

	const contentJSX = () =>
		!isFullWidthForm ? (
			<Col
				start={hasFormOnLeft ? 8 : 1}
				span={5}
				mdSpan={12}
				className="form-content"
			>
				{content.headline && (
					<Text variant="headline_4" color={headingColor}>
						{content.headline}
					</Text>
				)}
				{content.subcopy && (
					<Text
						tag="div"
						variant="paragraph_M"
						color={textColor}
						dangerouslySetInnerHTML={{
							__html: richTextResolver(content.subcopy),
						}}
					/>
				)}
				{content.image.url && (
					<div className="form-content-image">
						<CloudinaryImage
							url={content.image.url}
							alt={content.image.alt || "form image"}
							quality="auto"
							lazyload
							fetchFormat="auto"
						/>
					</div>
				)}
				{content.logos && (
					<HorizontalList
						listItems={content.logos.brandLogos?.map((logo, i) => {
							if (logo?.image?.image) {
								return (
									<CloudinaryImage
										key={`logo-image-${i}`}
										url={logo?.image?.image}
										quality="auto"
										lazyload
										fetchFormat="auto"
										sizes={{
											default: {
												height: 20,
											},
										}}
										alt={logo?.image?.altTag}
									/>
								);
							}
						})}
						maxItems={parseInt(content.logos.maxLogosInRow, 10)}
						labelConfig={{
							text: content.logos.labelText,
						}}
						gap="32px"
					/>
				)}
			</Col>
		) : (
			<></>
		);

	return (
		<StyledFormContainer
			className="form-container-component"
			topBackgroundColor={topBackgroundColor}
			bottomBackgroundColor={bottomBackgroundColor}
			itemsOnFirstColumn={itemsOnFirstColumn}
		>
			<Grid vAlign="center">
				{hasFormOnLeft ? formJSX() : contentJSX()}
				{hasFormOnLeft ? contentJSX() : formJSX()}
			</Grid>
		</StyledFormContainer>
	);
};

FormContainer.propTypes = {
	isFullWidthForm: PropTypes.bool,
	content: PropTypes.object,
	formDetails: PropTypes.object,
	hasDarkBackground: PropTypes.bool,
	hasFormOnLeft: PropTypes.bool,
	topBackgroundColor: PropTypes.string,
	bottomBackgroundColor: PropTypes.string,
	itemsOnFirstColumn: PropTypes.number,
};

FormContainer.defaultProps = {
	isFullWidthForm: true,
	content: {},
	formDetails: {},
	hasDarkBackground: false,
	hasFormOnLeft: false,
	topBackgroundColor: "#ffffff",
	bottomBackgroundColor: "#ffffff",
	itemsOnFirstColumn: 0,
};

export default FormContainer;
