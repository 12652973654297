import {
	MOBILE_L_BP,
	TABLET_M_BP,
} from "@website-builder/utilities/constants/style-constants/screen-sizes.js";
import styled from "styled-components";

export const StyledFormContainer = styled.div`
	padding: 5rem 0px;
	background: ${({ topBackgroundColor, bottomBackgroundColor }) =>
		`linear-gradient(180deg, ${topBackgroundColor}  0 calc(50% - 2.5rem), ${bottomBackgroundColor}  calc(50% - 2.5rem) 100%)`};

	.form-content {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		@media only screen and (max-width: ${TABLET_M_BP}px) {
			justify-self: center;
			margin-bottom: 1rem;
		}
	}

	.form-content-image {
		display: flex;
		justify-content: center;

		picture img {
			max-width: 100%;
			@media only screen and (max-width: ${TABLET_M_BP}px) {
				max-width: 480px;
			}
			@media only screen and (max-width: ${MOBILE_L_BP}px) {
				max-width: 90vw;
			}
		}
	}
	@media only screen and (max-width: ${TABLET_M_BP}px) {
		padding: 1.5rem 0px;
	}
`;
